import { Component } from '@angular/core';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
  selector: 'app-mobile-menu-commands',
  standalone: true,
  imports: [],
  templateUrl: './mobile-menu-commands.component.html',
  styleUrl: './mobile-menu-commands.component.scss',
})
export class MobileMenuCommandsComponent {
  constructor(private themeService: ThemeService) {}
  close() {
    this.themeService.clear();
  }

  toggleMinicart() {
    this.themeService.minicartOpen();
  }

  toggleSearch() {
    this.themeService.searchOpen();
  }
}
