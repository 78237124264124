<div class="py-4">
  <div class="d-flex col icons justify-content-end">
    <svg
      (click)="toggleSearch()"
      class="action"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="11.5" r="7.625" stroke="black" stroke-width="1.75" />
      <path d="M17.5 17L22.5 22" stroke="black" stroke-width="1.75" />
    </svg>

    <svg
      (click)="toggleMinicart()"
      width="25"
      class="action"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7457 18.6472L19.7574 9.05032C19.7053 8.66021 19.3933 8.34811 18.9771 8.34811H17.0786V6.42354C17.0786 3.9528 15.076 1.9502 12.6052 1.9502C10.1345 1.9502 8.13187 3.9528 8.13187 6.42354V8.34811H5.97322C5.58311 8.34811 5.24501 8.66021 5.19299 9.05032L4.17869 18.6472C4.10066 19.4795 4.38675 20.3377 4.93291 20.9619C5.50508 21.5861 6.31133 21.9502 7.14358 21.9502H17.7548C18.587 21.9502 19.3933 21.5861 19.9654 20.9879C20.5636 20.3637 20.8497 19.5055 20.7457 18.6472ZM9.69234 6.44954C9.69234 4.83706 10.9927 3.53667 12.6052 3.53667C14.2177 3.53667 15.5181 4.83706 15.5181 6.44954V8.37412H9.69234V6.44954ZM18.8471 19.9476C18.587 20.2337 18.1969 20.4157 17.8068 20.4157H7.19559C6.80547 20.4157 6.41536 20.2337 6.12927 19.9476C5.84319 19.6355 5.71315 19.2454 5.76516 18.8553L6.70144 9.9606H18.3009L19.2112 18.8553C19.2372 19.2454 19.1072 19.6355 18.8471 19.9476Z"
        fill="black"
      />
      <rect x="8" y="9" width="1.5" height="3" rx="0.75" fill="black" />
      <rect x="15.5" y="9" width="1.5" height="3" rx="0.75" fill="black" />
    </svg>

    <svg
      (click)="close()"
      width="25"
      class="action"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="18.1816"
        y1="17.7188"
        x2="6.86787"
        y2="6.40511"
        stroke="black"
        stroke-width="1.75"
      />
      <line
        x1="18.1187"
        y1="6.36872"
        x2="6.80501"
        y2="17.6824"
        stroke="black"
        stroke-width="1.75"
      />
    </svg>
  </div>
</div>
